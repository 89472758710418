<template>
  <div>
    <VueLightbox
      v-if="filesLightboxVisible"      
      :show-gallery="true"
      :index="lightboxIndex"
      :items="getFilesUrls"
      :gallery-position="bottom"
      @close="filesLightboxVisible = false"
    />

    <VueLightbox
      v-if="docsLightboxVisible"      
      :show-gallery="true"
      :index="lightboxIndex"
      :items="getApprovalDocsUrls"
      :gallery-position="bottom"
      @close="docsLightboxVisible = false"
    />

    <CModal
      title="Confirm approval"
      :show.sync="warningApproveModal"
    >
      Are you sure you want to approve this creative?

      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          @click="approveCreativeModal"
        >
          Approve
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningApproveModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CModal
      title="Confirm rejection"
      :show.sync="warningRejectModal"
    >
      Are you sure you want to reject this creative?
      <CTextarea        
        v-model="rejectionReason"
        class="mt-3"
        label="Rejection Reason"
        placeholder=""
        horizontal
        rows="5"
        required
        invalid-feedback="Please enter comments to save"
      />
      <template #footer>
        <ButtonLoading
          color="primary"
          :loading="isLoading"
          :disabled="!rejectionReason"
          @click="rejectCreativeModal"
        >
          Reject
        </ButtonLoading>

        <CButton
          color="danger"
          @click="warningRejectModal = false"
        >
          Cancel
        </CButton>
      </template>
    </CModal>

    <CCard>
      <div
        v-if="isLoading"
        :style="{ height: '80vh' }"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <CSpinner
          style="width:4rem;height:4rem;"
          color="info"
          grow
        />
      </div>
      <div v-else>
        <CCardHeader>
          Creative {{ selectedCreative.id }}
          <a
            href
            class="ml-1"
            @click.prevent="
              copyIdToClipboard(getSafe(() => selectedCreative.id))
            "
          ><CIcon
            name="cil-copy"
          /></a> 
        </CCardHeader>
        <CCardBody>
          <CForm>
            <dl class="row">
              <dt class="col-12 col-sm-3 text-sm-right">
                Account
              </dt>
              <dd class="col-sm-9">                
                <router-link
                  :to="{ name: 'SellerAccountDetails', params: { id: getSafe(() => selectedCreative.account.id)} }"
                  target="_blank"                 
                >
                  {{ getSafe(() => selectedCreative.account.name) }}
                </router-link>

                <a
                  href
                  class="ml-1"
                  @click.prevent="
                    copyAccountIdToClipboard(getSafe(() => selectedCreative.account.id))
                  "
                ><CIcon
                  name="cil-copy"
                /></a>  
              </dd>             
              <dt class="col-12 col-sm-3 text-sm-right">
                Name
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.name) }}
              </dd>
              <dt 
                v-if="selectedCreative.creationDate" 
                class="col-12 col-sm-3 text-sm-right"
              >
                Created At
              </dt>
              <dd 
                v-if="selectedCreative.creationDate" 
                class="col-sm-9"
              >
                {{ formatDate(selectedCreative.creationDate) }}
              </dd>
              <dt class="col-12 col-sm-3 text-sm-right">
                Review status
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.status) }}
                <div
                  v-if="selectedCreative.adminReviewBy && selectedCreative.adminReviewDate"
                >
                  <small> by {{ getSafe(() => selectedCreative.adminReviewBy.email) }} at {{ formatDate(selectedCreative.adminReviewDate) }}</small>
                </div>
              </dd>
              <dt class="col-12 col-sm-3 text-sm-right">
                Enabled 
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.enabled) ? 'Yes' : 'No' }}
                <div
                  v-if="selectedCreative.updatedBy && selectedCreative.updatedAt"                  
                >
                  <small>
                    <div 
                      v-if="selectedCreative.enabled"
                    >
                      activated by {{ getSafe(() => selectedCreative.updatedBy.email) }} at {{ formatDate(selectedCreative.updatedAt) }}
                    </div>
                    <div v-else>
                      deactivated by {{ getSafe(() => selectedCreative.updatedBy.email) }} at {{ formatDate(selectedCreative.updatedAt) }}
                    </div>
                  </small>                
                </div>                
              </dd>
              <dt 
                v-if="selectedCreative.stats" 
                class="col-12 col-sm-3 text-sm-right"
              >
                Buyer Approval Statistics
              </dt>
              <dd 
                v-if="selectedCreative.stats" 
                class="col-sm-9"
              >               
                <div>Total: {{ getSafe(() => selectedCreative.stats.total) }}</div>
                <div>Pending: {{ getSafe(() => selectedCreative.stats.pending) }}</div>
                <div>Approved: {{ getSafe(() => selectedCreative.stats.approved) }}</div>
                <div>Rejected: {{ getSafe(() => selectedCreative.stats.rejected) }}</div>
              </dd>          
              <dt 
                v-if="selectedCreative.externalId" 
                class="col-12 col-sm-3 text-sm-right"
              >
                External ID
              </dt>
              <dd 
                v-if="selectedCreative.externalId" 
                class="col-sm-9"
              >
                {{ getSafe(() => selectedCreative.externalId) }}
              </dd> 
              <dt 
                v-if="selectedCreative.cmsId" 
                class="col-12 col-sm-3 text-sm-right"
              >
                CMS ID
              </dt>
              <dd 
                v-if="selectedCreative.cmsId" 
                class="col-sm-9"
              >
                {{ getSafe(() => selectedCreative.cmsId) }}
              </dd>                       
              <dt 
                v-if="selectedCreative.landingPageUrl" 
                class="col-12 col-sm-3 text-sm-right"
              >
                Landing Page Url
              </dt>
              <dd 
                v-if="selectedCreative.landingPageUrl" 
                class="col-sm-9"
              >
                <a
                  v-if="Boolean(selectedCreative.landingPageUrl)"
                  :href="landingPage"
                  target="_blank"
                >
                  {{ selectedCreative.landingPageUrl }}
                </a>               
              </dd>           
              <dt class="col-12 col-sm-3 text-sm-right">
                Category
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.category.name) }}
              </dd>
              <dt class="col-12 col-sm-3 text-sm-right">
                Traffic source
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.trafficSource.name) }}
              </dd>
              <dt class="col-12 col-sm-3 text-sm-right">
                Country
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.country.name) }}
              </dd>
              <dt class="col-12 col-sm-3 text-sm-right">
                Language
              </dt>
              <dd class="col-sm-9">
                {{ getSafe(() => selectedCreative.language.name) }}
              </dd>  
              
              <dt class="mt-2 col-12 col-sm-3 text-sm-right">
                Media files uploaded
              </dt>
              <dd class="col-sm-9 mt-2">
                <ButtonLoading
                  v-if="getFilesUrls && getFilesUrls.length"                  
                  type="button"
                  size="sm"
                  color="primary"
                  @click="filesLightboxVisible = true"
                >
                  Show files
                </ButtonLoading>
                <div 
                  v-else
                  class="mt-2 text-muted"
                >
                  No files
                </div>
              </dd>

              <dt class="mt-2 col-12 col-sm-3 text-sm-right">
                Approval documents uploaded
              </dt>
              <dd class="col-sm-9 mt-2">
                <ButtonLoading
                  v-if="getApprovalDocsUrls && getApprovalDocsUrls.length"                  
                  type="button"
                  size="sm"
                  color="primary"
                  @click="docsLightboxVisible = true"
                >
                  Show docs
                </ButtonLoading>
                <div 
                  v-else
                  class="text-muted"
                >
                  No docs
                </div>
              </dd>

              <dt
                v-if="selectedCreative && selectedCreative.status === 'ReviewPending'"
                class="mt-3 offset-sm-3 col-12 col-sm-3"
              >
                <ButtonLoading                  
                  type="button"
                  color="success"
                  @click="warningApproveModal = true"
                >
                  Approve
                </ButtonLoading>

                <ButtonLoading                  
                  type="button"
                  color="danger"
                  class="ml-3"            
                  @click="warningRejectModal = true"
                >
                  Reject
                </ButtonLoading>
              </dt>
            </dl>
            <ReviewNotes            
              :parent-object="selectedCreative"
            />
          </CForm>
        </CCardBody>        
      </div>
    </CCard>
    <CCard>
      <div v-if="selectedCreative.stats && selectedCreative.stats.buyerReviews">
        <CCardHeader>
          <slot name="header">        
            <CIcon
              name="cil-check"
              class="mr-1"
            /> Approvals
          </slot>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            small
            border
            sorter
            hover
            column-filter
            :items="selectedCreative.stats.buyerReviews"
            :fields="fields"
            :items-per-page="10"
            :pagination="{align: 'center'}"
          >
            <template #buyerName="{item}">
              <td>
                <router-link
                  :to="{ name: 'BuyerAccountDetails', params: { id: getSafe(() => item.buyerId)} }"
                  target="_blank"                 
                >
                  {{ getSafe(() => item.buyerName) }}
                </router-link>

                <a
                  href
                  class="ml-1"
                  @click.prevent="
                    copyAccountIdToClipboard(getSafe(() => item.buyerId))
                  "
                ><CIcon
                  name="cil-copy"
                /></a>
              </td>
            </template>

            <template #status="{item}">
              <td>
                {{ item.status }}                
              </td>
            </template>

            <template #rejectionReason="{item}">
              <td>
                {{ item.rejectionReason }}
              </td>
            </template>

            <template #formattedReviewedDate="{item}">
              <td>
                {{ item.formattedReviewedDate }}                
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </div>
    </CCard>
  </div>
</template>

<script>

import {  mapState,  mapActions, mapGetters } from 'vuex'
import { showSuccessMessage, showErrorMessage } from '@/notification-utils'
import { dateTimeFormat } from '@/utils'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import ReviewNotes from '../ReviewNotes/ReviewNotes.vue'
import VueLightbox from 'vue-lightbox-lite'
import 'vue-lightbox-lite/dist/index.css'

export default {
  name: 'Creative',

  title: 'Review Creative',

  components: {    
    ReviewNotes,
    VueLightbox
  },

  mixins: [copyToClipboardMixin],

  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      isLoading: false,
      rejectionReason: '',
      warningApproveModal: false,
      warningRejectModal: false,    
      filesLightboxVisible: false,
      docsLightboxVisible: false,
      lightboxIndex: 0
    }
  },

  computed: {    
    ...mapState('creative', ['selectedCreative']), 
    ...mapGetters('creative', ['getFilesUrls','getApprovalDocsUrls']),

    fields () {
        return [
          { key: 'buyerName', label: 'Buyer', sortable: true },             
          { key: 'status', label: 'Status', sortable: true },               
          { key: 'rejectionReason', label: 'Rejection Reason', filter: true, sorter: false },
          { key: 'formattedReviewedDate', label: 'Reviewed At', sortable: true },
        ]
      },

    formattedBuyerReviews() {
      return this.selectedCreative.stats.buyerReviews        
        .map(review => {
          return {
            ...review,
            formattedReviewedDate: this.formatDate(review.reviewedDate)                    
          }
      })
    },

    landingPage() {
      return this.handleURLProtocol(this.selectedCreative.landingPageUrl)
    },   
  },

  async created() {
    this.isLoading = true    
    await this.getCreativeById(this.id)   
    this.isLoading = false
  },

  methods: {    
    ...mapActions('creative', ['getCreativeById','approveCreative','rejectCreative']),

    async approveCreativeModal() {
      try {
        this.isLoading = true
        await this.approveCreative(this.selectedCreative.id)
        this.isLoading = false
        showSuccessMessage("Successfully approved creative")
      } catch(error) {
        showErrorMessage("Failed to approve creative")
      } finally {
        this.warningApproveModal = false
        this.$router.push({ name: 'ScreeningPendingCreatives' })
      }
    },

    async rejectCreativeModal() {
      try {
        this.isLoading = true
        await this.rejectCreative({ id: this.selectedCreative.id, reason: this.rejectionReason })
        this.isLoading = false
        showSuccessMessage("Successfully rejected creative")
      } catch(error) {
        showErrorMessage("Failed to reject creative")
      } finally {
        this.warningRejectModal = false
        this.$router.push({ name: 'ScreeningPendingCreatives' })
      }
    },

    copyIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Creative ID copied to clipboard')
    },

    copyAccountIdToClipboard(id) {
      this.copyTextToClipboard(id)
      showSuccessMessage('Account ID copied to clipboard')
    },

    formatDate(date) {
      return date ? this.getSafe(() => dateTimeFormat.format(new Date(date))) : ''
    }    
  }
}
</script>